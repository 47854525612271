import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SocialMediaIcon() {
  return (
    <SvgIcon viewBox="0 0 400 400">
      {" "}
      {/* Specify the viewBox based on your SVG dimensions */}
      {/* Your SVG content */}
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="300.000000pt"
        height="300.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#FFFFFF"
          stroke="none"
        >
          <path
            d="M1542 4904 c-110 -29 -210 -115 -260 -222 l-27 -57 0 -2060 0 -2060
23 -57 c27 -67 103 -155 167 -192 98 -58 74 -57 1141 -54 l979 3 60 24 c83 33
183 133 216 216 l24 60 0 2055 0 2055 -24 60 c-18 44 -42 78 -90 126 -48 48
-82 72 -126 90 l-60 24 -985 2 c-831 2 -993 0 -1038 -13z m833 -258 c67 -67
22 -172 -72 -169 -50 1 -93 48 -93 99 0 81 108 127 165 70z m506 -5 c84 -84
-33 -216 -128 -145 -76 57 -38 174 57 174 33 0 48 -6 71 -29z m714 -385 c18
-8 38 -23 44 -34 8 -15 11 -507 11 -1667 l0 -1647 -29 -29 -29 -29 -1032 0
-1032 0 -29 29 -29 29 0 1647 c0 1158 3 1652 11 1666 26 49 7 48 1077 49 843
0 1008 -2 1037 -14z m-601 -3665 c14 -13 28 -36 31 -52 8 -37 -18 -93 -51
-108 -17 -8 -145 -11 -415 -11 -428 0 -435 1 -459 59 -21 51 7 114 60 134 8 3
194 5 412 4 l396 -2 26 -24z"
          />
          <path
            d="M1670 2560 l0 -1510 890 0 890 0 0 1510 0 1510 -890 0 -890 0 0
-1510z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
