import React, { useState, useEffect } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import ResidenceImage from "../images/Address-rafiki.png";

import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";

import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";

import { zodResolver } from "@hookform/resolvers/zod";

import { ResidenceSchema } from "../schemas/residenceSchema";
import "../styles/MuiTelInput.css";

const Residence = (props) => {
  let navigate = useNavigate();
  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    return savedData ? JSON.parse(savedData) : {};
  };

  const saveFormDataToLocalStorage = (data) => {
    const existingData = loadFormDataFromLocalStorage();
    const updatedData = { ...existingData, ...data };
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };
  let formDataString = localStorage.getItem("formData");
  let defaultValues = JSON.parse(formDataString);
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData) {
      props.updateUser(formData);
    }
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name).then((isValid) => {
      if (isValid) {
        saveFormDataToLocalStorage({ [name]: value });

        formDataString = localStorage.getItem("formData");
        defaultValues = JSON.parse(formDataString);
        props.updateUser({ [name]: value });
      }
    });
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ResidenceSchema),
    defaultValues: { residence: defaultValues.residence },
  });

  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const onSubmit = (data) => {
    props.updateUser(data);

    saveFormDataToLocalStorage(data);
    //props.handleFormCompletion();

    navigate("/status");
  };

  return (
    <div className="flex">
      {" "}
      <div id="form-start"></div>
      <Form
        className=" w-full md:w-1/2  my-[150px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <motion.div
          className="col-md-8 offset-md-2"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}
        >
          {window.innerWidth < 768 && (
            <div className="flex justify-center">
              <img
                className="w-[200px] h-[200px]"
                src={ResidenceImage}
                alt="description"
              />
            </div>
          )}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[30px] md:text-[40px]">
                    {" "}
                    أين تسكن؟{" "}
                  </h1>
                </Form.Label>{" "}
              </div>

              <div style={{ fontFamily: "Bold" }} className="pt-3">
                <Form.Group controlId="residence" dir="rtl">
                  <TextField
                    dir="rtl"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    rows={3}
                    multiline
                    fullWidth
                    label="العنوان التفصيلي*"
                    defaultValue=""
                    {...register("residence")}
                    InputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.residence !== undefined}
                  />
                  {errors.residence && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.residence.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center pt-4">
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
            >
              حفظ
            </Button>
          </div>
        </motion.div>
      </Form>
      {window.innerWidth >= 768 && (
        <div className="flex justify-center w-1/2 items-center my-16">
          <div>
            <img
              className=" w-[200px] h-[200px] md:w-[600px] md:h-[600px]"
              src={ResidenceImage}
              alt="description"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Residence;
