import { z } from "zod";
import { matchIsValidTel } from "mui-tel-input";

const facebookPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
const instagramPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com\/)(?:[a-zA-Z0-9._]+)(?:\/)?/i;
const twitterPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:twitter\.com\/)(?:[a-zA-Z0-9_]+)(?:\/)?/i;
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phonePattern = /^\d+$/;
const noCharsPattern = /^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

export const TravelSchema = z.object({
  dream_job: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  dream_country: z.string().optional().nullable(),
});
