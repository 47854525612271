import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import SocialMediaImage from "../images/Contact us-amico.png";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";

import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";

import { zodResolver } from "@hookform/resolvers/zod";

import { MuiTelInput } from "mui-tel-input";
import { SocialMediaSchema } from "../schemas/socialMediaSchema";
import "../styles/MuiTelInput.css";
import { BASE_API_URL } from "../utils/constants";
import axios from "axios";

const SocialMedia = (props) => {
  let navigate = useNavigate();

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}/register`,
        props.formData
      );
    } catch (error) {}
  };
  const [socialMediaCompleted, setSocialMediaCompleted] = useState(
    JSON.parse(localStorage.getItem("socialMediaCompleted"))
  );

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData && formData.first_phone_number) {
      setSocialMediaCompleted(true);
    }
  }, []);
  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    return savedData ? JSON.parse(savedData) : {};
  };
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData && formData.first_phone_number) {
      setSocialMediaCompleted(true);
      props.updateUser(formData);
    }
  }, []);

  const saveFormDataToLocalStorage = (data) => {
    const existingData = loadFormDataFromLocalStorage();
    const updatedData = { ...existingData, ...data };
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };
  let formDataString = localStorage.getItem("formData");
  let defaultValues = JSON.parse(formDataString);
  useEffect(() => {
    localStorage.setItem(
      "socialMediaCompleted",
      JSON.stringify(socialMediaCompleted)
    );
  }, [socialMediaCompleted]);
  const checkCompletion = (defaultValues) => {
    if (defaultValues.first_phone_number) {
      setSocialMediaCompleted(true);
      handleSave();
    } else {
      setSocialMediaCompleted(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name).then((isValid) => {
      if (isValid) {
        saveFormDataToLocalStorage({ [name]: value });

        formDataString = localStorage.getItem("formData");
        defaultValues = JSON.parse(formDataString);
        checkCompletion(defaultValues);
        props.updateUser({ [name]: value });
      }
    });
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(SocialMediaSchema),

    defaultValues: {
      first_phone_number: defaultValues.first_phone_number,
      second_phone_number: defaultValues.second_phone_number,
      email: defaultValues.email,
      facebook: defaultValues.facebook,
      instagram: defaultValues.instagram,
      twitter: defaultValues.twitter,
    },
  });

  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const onSubmit = (data) => {
    props.updateUser(data);

    saveFormDataToLocalStorage(data);
    navigate("/residence");
  };

  return (
    <div className="flex">
      {" "}
      <div id="form-start"></div>
      <Form
        className="md:w-1/2  my-[150px]   "
        onSubmit={handleSubmit(onSubmit)}
      >
        <motion.div
          className="col-md-8 offset-md-2"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}
        >
          {window.innerWidth < 768 && (
            <div className="flex justify-center">
              <img
                className="w-[200px] h-[200px]"
                src={SocialMediaImage}
                alt="description"
              />
            </div>
          )}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[30px] md:text-[40px]">
                    {" "}
                    كيف يمكننا التواصل معك؟
                  </h1>
                </Form.Label>{" "}
              </div>
              <div
                className="flex justify-center flex-wrap gap-4 pt-5 2xl:justify-center 2xl:flex-nowrap"
                dir="rtl"
              >
                <Form.Group
                  controlId="first_phone_number"
                  dir="rtl"
                  style={{ fontFamily: "Bold" }}
                >
                  <Controller
                    name="first_phone_number"
                    control={control}
                    render={({
                      field: { ref: fieldRef, value, ...fieldProps },
                      fieldState,
                    }) => (
                      <MuiTelInput
                        sx={{
                          width: 250,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { border: "1px solid #108cf2" },
                          },
                        }}
                        className="MuiListItemText-root MuiTypography-body1 .MuiListItemText-primary .muirtl-10hburv-MuiTypography-root MuiTelInput-ListItemText-country muirtl-weeiwk-MuiListItemText-root MuiTelInput-TextField MuiTelInput-IconButton MuiTelInput-Flag MuiTelInput-FlagImg MuiTelInput-Menu MuiTelInput-MenuItem MuiTelInput-ListItemIcon-flag MuiTelInput-ListItemText-country MuiTelInput-Typography-calling-code"
                        langOfCountryName="ar"
                        label="رقم هاتف أوّل*"
                        inputProps={{
                          style: { fontFamily: "Medium", color: "#225075" },
                          onBlur: (e) => handleInputChange(e),
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Medium", color: "#108cf2" },
                        }}
                        style={{ fontFamily: "Medium" }}
                        {...fieldProps}
                        value={value ?? ""}
                        inputRef={fieldRef}
                        defaultCountry="SY"
                        FormHelperTextProps={{
                          style: { fontFamily: "Medium", textAlign: "right" },
                        }}
                        error={errors.first_phone_number !== undefined}
                      />
                    )}
                  />
                  {errors.first_phone_number && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.first_phone_number.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="second_phone_number"
                  dir="rtl"
                  style={{ fontFamily: "Bold" }}
                >
                  <Controller
                    name="second_phone_number"
                    control={control}
                    render={({
                      field: { ref: fieldRef, value, ...fieldProps },
                      fieldState,
                    }) => (
                      <MuiTelInput
                        sx={{
                          width: 250,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { border: "1px solid #108cf2" },
                          },
                        }}
                        className="MuiListItemText-root MuiTypography-body1 .MuiListItemText-primary .muirtl-10hburv-MuiTypography-root MuiTelInput-ListItemText-country muirtl-weeiwk-MuiListItemText-root MuiTelInput-TextField MuiTelInput-IconButton MuiTelInput-Flag MuiTelInput-FlagImg MuiTelInput-Menu MuiTelInput-MenuItem MuiTelInput-ListItemIcon-flag MuiTelInput-ListItemText-country MuiTelInput-Typography-calling-code"
                        langOfCountryName="ar"
                        label="رقم هاتف ثانٍ"
                        inputProps={{
                          style: { fontFamily: "Medium", color: "#225075" },
                          onBlur: (e) => handleInputChange(e),
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Medium", color: "#108cf2" },
                        }}
                        style={{ fontFamily: "Medium" }}
                        {...fieldProps}
                        value={value ?? ""}
                        inputRef={fieldRef}
                        defaultCountry="SY"
                        FormHelperTextProps={{
                          style: { fontFamily: "Medium", textAlign: "right" },
                        }}
                        error={errors.second_phone_number !== undefined}
                      />
                    )}
                  />
                  {errors.second_phone_number && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.second_phone_number.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="email" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="البريد الالكتروني"
                    {...register("email")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.email !== undefined}
                  />
                  {errors.email && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="facebook" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="حساب الفيسبوك"
                    {...register("facebook")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.facebook !== undefined}
                  />
                  {errors.facebook && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.facebook.message}
                    </p>
                  )}
                </Form.Group>
              </div>
              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap"
                dir="rtl"
              >
                {" "}
                <Form.Group controlId="instagram" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="حساب الانستاغرام"
                    {...register("instagram")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.instagram !== undefined}
                  />
                  {errors.instagram && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.instagram.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="twitter" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="حساب تويتر (X)"
                    {...register("twitter")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.twitter !== undefined}
                  />
                  {errors.twitter && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.twitter.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center pt-4">
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
            >
              حفظ
            </Button>
          </div>
        </motion.div>
      </Form>
      {window.innerWidth >= 768 && (
        <div className="flex justify-center w-1/2 items-center my-16">
          <div>
            <img
              className=" w-[200px] h-[200px] md:w-[600px] md:h-[600px]"
              src={SocialMediaImage}
              alt="description"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
