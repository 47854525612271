import { z } from "zod";

const noCharsPattern = /^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

export const ResumeSchema = z.object({
  education: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  school: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  speciality: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  study_year: z.string(),
  skills: z

    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  current_job: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  work_place: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
  work_year: z.any().nullable(),
});
