import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import Autocomplete from "@mui/material/Autocomplete";
import personalImage from "../images/Personal site-rafiki.png";
import Box from "@mui/material/Box";
import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";

import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";

import { zodResolver } from "@hookform/resolvers/zod";

import { InfoSchema } from "../schemas/infoSchema";
import "../styles/MuiTelInput.css";
//import { countries } from "./countries";
import dayjs from "dayjs";

const Info = (props) => {
  let navigate = useNavigate();
  props.updateUser({ userId: localStorage.getItem("userId") });
  let newDate =
    JSON.parse(localStorage.getItem("formData")).birth_date || "1-1-1990";
  const [Date, setDate] = useState(dayjs(newDate));
  const [infoCompleted, setInfoCompleted] = useState(
    JSON.parse(localStorage.getItem("infoCompleted"))
  );
  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    return savedData ? JSON.parse(savedData) : {};
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (
      formData &&
      formData.arabic_first_name &&
      formData.arabic_father_name &&
      formData.arabic_last_name &&
      formData.arabic_mother_name
    ) {
      setInfoCompleted(true);
      props.updateUser(formData);
    }
  }, []);

  const saveFormDataToLocalStorage = (data) => {
    const existingData = loadFormDataFromLocalStorage();
    const updatedData = { ...existingData, ...data };
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };

  let formDataString = localStorage.getItem("formData");
  let defaultBirthCountry = null;
  let defaultOriginCountry = null;
  let defaultNationalityCountry = null;
  if (formDataString) {
    const formData = JSON.parse(formDataString);
    const place_of_birth = formData.place_of_birth || " ";
    defaultBirthCountry = countries.find((country) => {
      return country.label === place_of_birth;
    });

    const origin = formData.origin || " ";
    defaultOriginCountry = countries.find((country) => {
      return country.label === origin;
    });

    const nationality = formData.nationality || " ";
    defaultNationalityCountry = countries.find((country) => {
      return country.label === nationality;
    });
  }

  useEffect(() => {
    localStorage.setItem("infoCompleted", JSON.stringify(infoCompleted));
  }, [infoCompleted]);

  let defaultValues = JSON.parse(formDataString);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(InfoSchema),

    defaultValues: {
      arabic_first_name: defaultValues.arabic_first_name,
      arabic_father_name: defaultValues.arabic_father_name,
      arabic_last_name: defaultValues.arabic_last_name,
      arabic_mother_name: defaultValues.arabic_mother_name,
      first_name: defaultValues.first_name,
      last_name: defaultValues.last_name,
      father_name: defaultValues.father_name,
      mother_name: defaultValues.mother_name,
      place_of_birth: defaultValues.place_of_birth,
      origin: defaultValues.origin,
      nationality: defaultValues.nationality,
    },
  });

  const checkCompletion = (defaultValues) => {
    if (
      defaultValues.arabic_first_name &&
      defaultValues.arabic_father_name &&
      defaultValues.arabic_last_name &&
      defaultValues.arabic_mother_name
    ) {
      setInfoCompleted(true);
    } else {
      setInfoCompleted(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name).then((isValid) => {
      if (isValid) {
        saveFormDataToLocalStorage({ [name]: value });
        formDataString = localStorage.getItem("formData");
        defaultValues = JSON.parse(formDataString);
        checkCompletion(defaultValues);
        props.updateUser({ [name]: value });
      }
    });
  };

  const size = window.innerWidth;
  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const onSubmit = (data) => {
    props.updateUser(data);

    saveFormDataToLocalStorage(data);

    navigate("/socialmedia");
  };

  return (
    <div className="flex">
      {" "}
      <div id="form-start"></div>
      <Form
        className=" flex my-[150px] md:w-1/2 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <motion.div
          className="col-md-8 offset-md-2"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}
        >
          {window.innerWidth < 768 && (
            <div className="flex justify-center">
              <img
                className="w-[250px] h-[250px]"
                src={personalImage}
                alt="description"
              />
            </div>
          )}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center ">
                <Form.Label>
                  <h1
                    className="flex border-solid text-[30px] md:text-[40px]"
                    style={{ fontFamily: "Bold" }}
                  >
                    {" "}
                    تعريف شخصي{" "}
                  </h1>
                </Form.Label>{" "}
              </div>

              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap "
                dir="rtl"
              >
                <Form.Group controlId="arabic_first_name" dir="rtl">
                  <TextField
                    className="customTextField"
                    dir="rtl"
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="الاسم الأوّل*"
                    {...register("arabic_first_name")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },

                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.arabic_first_name !== undefined}
                  />
                  {errors.arabic_first_name && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.arabic_first_name.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="arabic_father_name" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    dir="rtl"
                    id="outlined-required"
                    label="اسم الأب*"
                    {...register("arabic_father_name")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.arabic_father_name !== undefined}
                  />
                  {errors.arabic_father_name && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.arabic_father_name.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="arabic_last_name" dir="rtl">
                  <TextField
                    dir="rtl"
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="اسم الشهرة*"
                    {...register("arabic_last_name")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.arabic_last_name !== undefined}
                  />
                  {errors.arabic_last_name && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.arabic_last_name.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="arabic_mother_name" dir="rtl">
                  <TextField
                    dir="rtl"
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    label="اسم الأم*"
                    {...register("arabic_mother_name")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.arabic_mother_name !== undefined}
                  />
                  {errors.arabic_mother_name && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.arabic_mother_name.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap ">
            <Form.Group controlId="first_name">
              <TextField
                sx={{
                  width: 250,
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "1px solid #108cf2" },
                  },
                }}
                id="outlined-required"
                label="First name"
                {...register("first_name")}
                inputProps={{
                  style: { fontFamily: "Medium", color: "#225075" },
                  onBlur: (e) => handleInputChange(e),
                }}
                InputLabelProps={{
                  style: { fontFamily: "Medium", color: "#108cf2" },
                }}
                error={errors.first_name !== undefined}
              />
              {errors.first_name && (
                <p
                  className="errorMsg"
                  style={{ fontFamily: "Regular", textAlign: "left" }}
                >
                  {errors.first_name.message}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="father_name">
              <TextField
                sx={{
                  width: 250,
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "1px solid #108cf2" },
                  },
                }}
                id="outlined-required"
                label="Father's name"
                {...register("father_name")}
                inputProps={{
                  style: { fontFamily: "Medium", color: "#225075" },
                  onBlur: (e) => handleInputChange(e),
                }}
                InputLabelProps={{
                  style: { fontFamily: "Medium", color: "#108cf2" },
                }}
                error={errors.father_name !== undefined}
              />
              {errors.father_name && (
                <p
                  className="errorMsg"
                  style={{ fontFamily: "Regular", textAlign: "left" }}
                >
                  {errors.father_name.message}
                </p>
              )}
            </Form.Group>

            <Form.Group controlId="last_name">
              <TextField
                sx={{
                  width: 250,
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "1px solid #108cf2" },
                  },
                }}
                id="outlined-required"
                label="Last name"
                {...register("last_name")}
                inputProps={{
                  style: { fontFamily: "Medium", color: "#225075" },
                  onBlur: (e) => handleInputChange(e),
                }}
                InputLabelProps={{
                  style: { fontFamily: "Medium", color: "#108cf2" },
                }}
                error={errors.last_name !== undefined}
              />
              {errors.last_name && (
                <p
                  className="errorMsg"
                  style={{ fontFamily: "Regular", textAlign: "left" }}
                >
                  {errors.last_name.message}
                </p>
              )}
            </Form.Group>

            <Form.Group controlId="mother_name">
              <TextField
                sx={{
                  width: 250,
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "1px solid #108cf2" },
                  },
                }}
                id="outlined-required"
                label="Mother's name"
                {...register("mother_name")}
                inputProps={{
                  style: { fontFamily: "Medium", color: "#225075" },
                  onBlur: (e) => handleInputChange(e),
                }}
                InputLabelProps={{
                  style: { fontFamily: "Medium", color: "#108cf2" },
                }}
                error={errors.mother_name !== undefined}
              />
              {errors.mother_name && (
                <p
                  className="errorMsg"
                  style={{ fontFamily: "Regular", textAlign: "left" }}
                >
                  {errors.mother_name.message}
                </p>
              )}
            </Form.Group>
          </div>
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap   "
                dir="rtl"
              >
                <Form.Group>
                  <Controller
                    name="place_of_birth"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => {
                      return (
                        <Autocomplete
                          value={defaultBirthCountry}
                          sx={{
                            width: 250,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { border: "1px solid #108cf2" },
                            },
                          }}
                          id="place_of_birth"
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) =>
                            onChange(data ? data.label : "")
                          }
                          renderOption={(props, option) => (
                            <Box
                              style={{ fontFamily: "Medium" }}
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.label}
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              inputRef={ref}
                              label="مكان الولادة"
                              inputProps={{
                                ...params.inputProps,
                                onBlur: (e) => handleInputChange(e),
                                style: {
                                  fontFamily: "Medium",
                                  color: "#225075",
                                },
                                autoComplete: "new-password",
                              }}
                              InputLabelProps={{
                                style: {
                                  fontFamily: "Medium",
                                  color: "#108cf2",
                                },
                              }}
                              error={errors.place_of_birth !== undefined}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  {errors.place_of_birth && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.place_of_birth.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  controlId="birth_date"
                  style={{ fontFamily: "Medium" }}
                >
                  <Controller
                    name="birth_date"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{
                              width: 250,
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { border: "1px solid #108cf2" },
                              },
                            }}
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#108cf2",
                                  },
                                },
                                inputProps: {
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#225075",
                                  },
                                },
                              },
                            }}
                            label="تاريخ الولادة"
                            {...field}
                            defaultValue={Date}
                            value={Date}
                            onChange={(newValue) => {
                              setDate(newValue);
                              saveFormDataToLocalStorage({
                                birth_date:
                                  dayjs(newValue).format("MM-DD-YYYY"),
                              });
                              props.updateUser({
                                birth_date:
                                  dayjs(newValue).format("MM-DD-YYYY"),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="origin"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        defaultValue={defaultOriginCountry}
                        id="origin"
                        sx={{
                          width: 250,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { border: "1px solid #108cf2" },
                          },
                        }}
                        options={countries}
                        autoHighlight
                        defaultChecked={"الجمهورية العربية السورية"}
                        getOptionLabel={(option) => option.label}
                        onChange={(_, data) => onChange(data ? data.label : "")}
                        renderOption={(props, option) => (
                          <Box
                            style={{ fontFamily: "Medium", color: "#225075" }}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            inputRef={ref}
                            label="البلدة الأصل"
                            inputProps={{
                              ...params.inputProps,
                              style: { fontFamily: "Medium", color: "#225075" },
                              onBlur: (e) => handleInputChange(e),
                              autoComplete: "new-password",
                            }}
                            InputLabelProps={{
                              style: { fontFamily: "Medium", color: "#108cf2" },
                            }}
                            error={errors.origin !== undefined}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.origin && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.origin.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="nationality"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        sx={{
                          width: 250,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { border: "1px solid #108cf2" },
                          },
                        }}
                        id="nationality"
                        defaultValue={defaultNationalityCountry}
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={(_, data) => onChange(data ? data.label : "")}
                        renderOption={(props, option) => (
                          <Box
                            style={{ fontFamily: "Medium" }}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            inputRef={ref}
                            label="الجنسية"
                            inputProps={{
                              ...params.inputProps,
                              style: { fontFamily: "Medium", color: "#225075" },
                              onBlur: (e) => handleInputChange(e),
                              autoComplete: "new-password",
                            }}
                            InputLabelProps={{
                              style: { fontFamily: "Medium", color: "#108cf2" },
                            }}
                            error={errors.nationality !== undefined}
                          />
                        )}
                      />
                    )}
                  />

                  {errors.nationality && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.nationality.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center pt-4">
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
            >
              حفظ
            </Button>
          </div>
        </motion.div>
      </Form>
      {window.innerWidth >= 768 && (
        <div className="flex justify-center w-1/2 items-center my-16">
          <div>
            <img
              className=" w-[200px] h-[200px] md:w-[800px] md:h-[800px]"
              src={personalImage}
              alt="description"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Info;

const education = [
  { label: "جامعي" },
  { label: "مهني" },
  { label: "ابتدائي" },
  { label: "غير" },
];
const MaritalStatus = [
  { label: "متزوج/متزوجة" },
  { label: "عزباء/أعزب" },
  { label: "مطلقة/مطلق" },
  { label: "أرملة/أرمل" },
];
const countries = [
  { code: "AD", label: "أندورا", phone: "376" },
  { code: "AE", label: "الإمارات العربية المتحدة", phone: "971" },
  { code: "AF", label: "أفغانستان", phone: "93" },
  { code: "AG", label: "أنتيغوا وبربودا", phone: "1-268" },
  { code: "AI", label: "أنغويلا", phone: "1-264" },
  { code: "AL", label: "ألبانيا", phone: "355" },
  { code: "AM", label: "أرمينيا", phone: "374" },
  { code: "AO", label: "أنغولا", phone: "244" },
  { code: "AQ", label: "القطب الجنوبي", phone: "672" },
  { code: "AR", label: "الأرجنتين", phone: "54" },
  { code: "AS", label: "ساموا الأمريكية", phone: "1-684" },
  { code: "AT", label: "النمسا", phone: "43" },
  { code: "AU", label: "أستراليا", phone: "61", suggested: true },
  { code: "AW", label: "أروبا", phone: "297" },
  { code: "AX", label: "جزر آلاند", phone: "358" },
  { code: "AZ", label: "أذربيجان", phone: "994" },

  { code: "BA", label: "البوسنة والهرسك", phone: "387" },
  { code: "BB", label: "بربادوس", phone: "1-246" },
  { code: "BD", label: "بنغلاديش", phone: "880" },
  { code: "BE", label: "بلجيكا", phone: "32" },
  { code: "BF", label: "بوركينا فاسو", phone: "226" },
  { code: "BG", label: "بلغاريا", phone: "359" },
  { code: "BH", label: "البحرين", phone: "973" },
  { code: "BI", label: "بوروندي", phone: "257" },
  { code: "BJ", label: "بنين", phone: "229" },
  { code: "BL", label: "سان بارتيلمي", phone: "590" },
  { code: "BM", label: "برمودا", phone: "1-441" },
  { code: "BN", label: "بروناي دار السلام", phone: "673" },
  { code: "BO", label: "بوليفيا", phone: "591" },
  { code: "BR", label: "البرازيل", phone: "55" },
  { code: "BS", label: "البهاما", phone: "1-242" },
  { code: "BT", label: "بوتان", phone: "975" },
  { code: "BV", label: "جزيرة بوفيه", phone: "47" },
  { code: "BW", label: "بوتسوانا", phone: "267" },
  { code: "BY", label: "بيلاروس", phone: "375" },
  { code: "BZ", label: "بليز", phone: "501" },

  { code: "CA", label: "كندا", phone: "1", suggested: true },
  {
    code: "CC",
    label: "جزر كوكوس (كيلينغ)",
    phone: "61",
  },
  {
    code: "CD",
    label: "جمهورية الكونغو الديمقراطية",
    phone: "243",
  },
  {
    code: "CF",
    label: "جمهورية أفريقيا الوسطى",
    phone: "236",
  },
  {
    code: "CG",
    label: "جمهورية الكونغو",
    phone: "242",
  },
  { code: "CH", label: "سويسرا", phone: "41" },
  { code: "CI", label: "ساحل العاج", phone: "225" },
  { code: "CK", label: "جزر كوك", phone: "682" },
  { code: "CL", label: "تشيلي", phone: "56" },
  { code: "CM", label: "الكاميرون", phone: "237" },
  { code: "CN", label: "الصين", phone: "86" },
  { code: "CO", label: "كولومبيا", phone: "57" },
  { code: "CR", label: "كوستاريكا", phone: "506" },
  { code: "CU", label: "كوبا", phone: "53" },
  { code: "CV", label: "الرأس الأخضر", phone: "238" },
  { code: "CW", label: "كوراساو", phone: "599" },
  { code: "CX", label: "جزيرة الكريسماس", phone: "61" },
  { code: "CY", label: "قبرص", phone: "357" },
  { code: "CZ", label: "جمهورية التشيك", phone: "420" },

  { code: "DE", label: "ألمانيا", phone: "49", suggested: true },
  { code: "DJ", label: "جيبوتي", phone: "253" },
  { code: "DK", label: "الدنمارك", phone: "45" },
  { code: "DM", label: "دومينيكا", phone: "1-767" },
  {
    code: "DO",
    label: "جمهورية الدومينيكان",
    phone: "1-809",
  },

  { code: "DZ", label: "الجزائر", phone: "213" },
  { code: "EC", label: "الإكوادور", phone: "593" },
  { code: "EE", label: "إستونيا", phone: "372" },
  { code: "EG", label: "مصر", phone: "20" },
  { code: "EH", label: "الصحراء الغربية", phone: "212" },
  { code: "ER", label: "إريتريا", phone: "291" },
  { code: "ES", label: "إسبانيا", phone: "34" },
  { code: "ET", label: "إثيوبيا", phone: "251" },
  { code: "FI", label: "فنلندا", phone: "358" },
  { code: "FJ", label: "فيجي", phone: "679" },
  {
    code: "FK",
    label: "جزر فوكلاند (مالفيناس)",
    phone: "500",
  },
  {
    code: "FM",
    label: "ولايات ميكرونيزيا الموحدة",
    phone: "691",
  },
  { code: "FO", label: "جزر فارو", phone: "298" },
  {
    code: "FR",
    label: "فرنسا",
    phone: "33",
    suggested: true,
  },

  { code: "GA", label: "الغابون", phone: "241" },
  { code: "GB", label: "المملكة المتحدة", phone: "44" },
  { code: "GD", label: "غرينادا", phone: "1-473" },
  { code: "GE", label: "جورجيا", phone: "995" },
  { code: "GF", label: "غويانا الفرنسية", phone: "594" },
  { code: "GG", label: "غيرنزي", phone: "44" },
  { code: "GH", label: "غانا", phone: "233" },
  { code: "GI", label: "جبل طارق", phone: "350" },
  { code: "GL", label: "جرينلاند", phone: "299" },
  { code: "GM", label: "غامبيا", phone: "220" },
  { code: "GN", label: "غينيا", phone: "224" },
  { code: "GP", label: "جوادلوب", phone: "590" },
  { code: "GQ", label: "غينيا الاستوائية", phone: "240" },
  { code: "GR", label: "اليونان", phone: "30" },
  {
    code: "GS",
    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    phone: "500",
  },
  { code: "GT", label: "غواتيمالا", phone: "502" },
  { code: "GU", label: "غوام", phone: "1-671" },
  { code: "GW", label: "غينيا بيساو", phone: "245" },
  { code: "GY", label: "غيانا", phone: "592" },
  { code: "HK", label: "هونغ كونغ", phone: "852" },
  {
    code: "HM",
    label: "جزر هيرد وماكدونالد",
    phone: "672",
  },

  { code: "HN", label: "هندوراس", phone: "504" },
  { code: "HR", label: "كرواتيا", phone: "385" },
  { code: "HT", label: "هايتي", phone: "509" },
  { code: "HU", label: "هنغاريا", phone: "36" },
  { code: "ID", label: "إندونيسيا", phone: "62" },
  { code: "IE", label: "أيرلندا", phone: "353" },
  { code: "IL", label: "إسرائيل", phone: "972" },
  { code: "IM", label: "جزيرة مان", phone: "44" },
  { code: "IN", label: "الهند", phone: "91" },
  { code: "IO", label: "إقليم المحيط الهندي البريطاني", phone: "246" },
  { code: "IQ", label: "العراق", phone: "964" },
  { code: "IR", label: "إيران، جمهورية", phone: "98" },
  { code: "IS", label: "آيسلندا", phone: "354" },
  { code: "IT", label: "إيطاليا", phone: "39" },
  { code: "JE", label: "جيرسي", phone: "44" },
  { code: "JM", label: "جامايكا", phone: "1-876" },
  { code: "JO", label: "الأردن", phone: "962" },
  { code: "JP", label: "اليابان", phone: "81", suggested: true },
  { code: "KE", label: "كينيا", phone: "254" },
  { code: "KG", label: "قيرغيزستان", phone: "996" },
  { code: "KH", label: "كمبوديا", phone: "855" },
  { code: "KI", label: "كيريباتي", phone: "686" },
  { code: "KM", label: "جزر القمر", phone: "269" },
  { code: "KN", label: "سانت كيتس ونيفيس", phone: "1-869" },
  { code: "KP", label: "كوريا الشمالية", phone: "850" },
  { code: "KR", label: "كوريا الجنوبية", phone: "82" },
  { code: "KW", label: "الكويت", phone: "965" },
  { code: "KY", label: "جزر كايمان", phone: "1-345" },
  { code: "KZ", label: "كازاخستان", phone: "7" },
  { code: "LA", label: "جمهورية لاو الديمقراطية", phone: "856" },

  { code: "LB", label: "لبنان", phone: "961" },
  { code: "LC", label: "سانت لوسيا", phone: "1-758" },
  { code: "LI", label: "ليختنشتاين", phone: "423" },
  { code: "LK", label: "سريلانكا", phone: "94" },
  { code: "LR", label: "ليبيريا", phone: "231" },
  { code: "LS", label: "ليسوتو", phone: "266" },
  { code: "LT", label: "ليتوانيا", phone: "370" },
  { code: "LU", label: "لوكسمبورغ", phone: "352" },
  { code: "LV", label: "لاتفيا", phone: "371" },
  { code: "LY", label: "ليبيا", phone: "218" },
  { code: "MA", label: "المغرب", phone: "212" },
  { code: "MC", label: "موناكو", phone: "377" },
  { code: "MD", label: "مولدافيا، جمهورية", phone: "373" },
  { code: "ME", label: "الجبل الأسود", phone: "382" },
  { code: "MF", label: "سانت مارتن (الجزء الفرنسي)", phone: "590" },
  { code: "MG", label: "مدغشقر", phone: "261" },
  { code: "MH", label: "جزر مارشال", phone: "692" },
  { code: "MK", label: "مقدونيا، جمهورية سابقة يوغوسلافيا", phone: "389" },
  { code: "ML", label: "مالي", phone: "223" },
  { code: "MM", label: "ميانمار", phone: "95" },
  { code: "MN", label: "منغوليا", phone: "976" },
  { code: "MO", label: "ماكاو", phone: "853" },
  { code: "MP", label: "جزر ماريانا الشمالية", phone: "1-670" },

  { code: "MQ", label: "مارتينيك", phone: "596" },
  { code: "MR", label: "موريتانيا", phone: "222" },
  { code: "MS", label: "مونتسرات", phone: "1-664" },
  { code: "MT", label: "مالطا", phone: "356" },
  { code: "MU", label: "موريشيوس", phone: "230" },
  { code: "MV", label: "جزر المالديف", phone: "960" },
  { code: "MW", label: "مالاوي", phone: "265" },
  { code: "MX", label: "المكسيك", phone: "52" },
  { code: "MY", label: "ماليزيا", phone: "60" },
  { code: "MZ", label: "موزمبيق", phone: "258" },
  { code: "NA", label: "ناميبيا", phone: "264" },
  { code: "NC", label: "كاليدونيا الجديدة", phone: "687" },
  { code: "NE", label: "النيجر", phone: "227" },
  { code: "NF", label: "جزيرة نورفولك", phone: "672" },
  { code: "NG", label: "نيجيريا", phone: "234" },
  { code: "NI", label: "نيكاراغوا", phone: "505" },
  { code: "NL", label: "هولندا", phone: "31" },
  { code: "NO", label: "النرويج", phone: "47" },
  { code: "NP", label: "نيبال", phone: "977" },
  { code: "NR", label: "ناورو", phone: "674" },
  { code: "NU", label: "نيوي", phone: "683" },
  { code: "NZ", label: "نيوزيلندا", phone: "64" },
  { code: "OM", label: "عمان", phone: "968" },
  { code: "PA", label: "بنما", phone: "507" },
  { code: "PE", label: "بيرو", phone: "51" },
  { code: "PF", label: "بولينيزيا الفرنسية", phone: "689" },
  { code: "PG", label: "بابوا غينيا الجديدة", phone: "675" },
  { code: "PH", label: "الفلبين", phone: "63" },
  { code: "PK", label: "باكستان", phone: "92" },
  { code: "PL", label: "بولندا", phone: "48" },
  { code: "PM", label: "سان بيير وميكلون", phone: "508" },
  { code: "PN", label: "بيتكيرن", phone: "870" },
  { code: "PR", label: "بورتوريكو", phone: "1" },
  { code: "PS", label: "فلسطين، دولة", phone: "970" },

  { code: "PT", label: "البرتغال", phone: "351" },
  { code: "PW", label: "بالاو", phone: "680" },
  { code: "PY", label: "باراغواي", phone: "595" },
  { code: "QA", label: "قطر", phone: "974" },
  { code: "RE", label: "لا ريونيون", phone: "262" },
  { code: "RO", label: "رومانيا", phone: "40" },
  { code: "RS", label: "صربيا", phone: "381" },
  { code: "RU", label: "الاتحاد الروسي", phone: "7" },
  { code: "RW", label: "رواندا", phone: "250" },
  { code: "SA", label: "المملكة العربية السعودية", phone: "966" },
  { code: "SB", label: "جزر سليمان", phone: "677" },
  { code: "SC", label: "سيشل", phone: "248" },
  { code: "SD", label: "السودان", phone: "249" },
  { code: "SE", label: "السويد", phone: "46" },
  { code: "SG", label: "سنغافورة", phone: "65" },
  { code: "SH", label: "سانت هيلينا", phone: "290" },
  { code: "SI", label: "سلوفينيا", phone: "386" },
  { code: "SJ", label: "سفالبارد وجان ماين", phone: "47" },
  { code: "SK", label: "سلوفاكيا", phone: "421" },
  { code: "SL", label: "سيراليون", phone: "232" },
  { code: "SM", label: "سان مارينو", phone: "378" },
  { code: "SN", label: "السنغال", phone: "221" },
  { code: "SO", label: "الصومال", phone: "252" },
  { code: "SR", label: "سورينام", phone: "597" },
  { code: "SS", label: "جنوب السودان", phone: "211" },
  { code: "ST", label: "ساو تومي وبرينسيب", phone: "239" },
  { code: "SV", label: "السلفادور", phone: "503" },
  { code: "SX", label: "سانت مارتن (الجزء الهولندي)", phone: "1-721" },

  { code: "SY", label: "الجمهورية العربية السورية", phone: "963" },
  { code: "SZ", label: "سوازيلاند", phone: "268" },
  { code: "TC", label: "جزر تركس وكايكوس", phone: "1-649" },
  { code: "TD", label: "تشاد", phone: "235" },
  { code: "TF", label: "المقاطعات الجنوبية الفرنسية", phone: "262" },
  { code: "TG", label: "توغو", phone: "228" },
  { code: "TH", label: "تايلاند", phone: "66" },
  { code: "TJ", label: "طاجيكستان", phone: "992" },
  { code: "TK", label: "توكيلاو", phone: "690" },
  { code: "TL", label: "تيمور الشرقية", phone: "670" },
  { code: "TM", label: "تركمانستان", phone: "993" },
  { code: "TN", label: "تونس", phone: "216" },
  { code: "TO", label: "تونغا", phone: "676" },
  { code: "TR", label: "تركيا", phone: "90" },
  { code: "TT", label: "ترينيداد وتوباغو", phone: "1-868" },
  { code: "TV", label: "توفالو", phone: "688" },
  { code: "TW", label: "تايوان", phone: "886" },

  { code: "TZ", label: "الجمهورية المتحدة لتنزانيا", phone: "255" },
  { code: "UA", label: "أوكرانيا", phone: "380" },
  { code: "UG", label: "أوغندا", phone: "256" },
  { code: "US", label: "الولايات المتحدة", phone: "1", suggested: true },
  { code: "UY", label: "أوروغواي", phone: "598" },
  { code: "UZ", label: "أوزبكستان", phone: "998" },
  { code: "VA", label: "الكرسي الرسولي (دولة الفاتيكان)", phone: "379" },
  { code: "VC", label: "سانت فنسنت وجزر غرينادين", phone: "1-784" },
  { code: "VE", label: "فنزويلا", phone: "58" },
  { code: "VG", label: "جزر العذراء البريطانية", phone: "1-284" },
  { code: "VI", label: "جزر فيرجن الأمريكية", phone: "1-340" },

  { code: "VN", label: "فيتنام", phone: "84" },
  { code: "VU", label: "فانواتو", phone: "678" },
  { code: "WF", label: "واليس وفوتونا", phone: "681" },
  { code: "WS", label: "ساموا", phone: "685" },
  { code: "XK", label: "كوسوفو", phone: "383" },
  { code: "YE", label: "اليمن", phone: "967" },
  { code: "YT", label: "مايوت", phone: "262" },
  { code: "ZA", label: "جنوب أفريقيا", phone: "27" },
  { code: "ZM", label: "زامبيا", phone: "260" },
  { code: "ZW", label: "زيمبابوي", phone: "263" },
];
