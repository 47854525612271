import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";
import "../styles/MuiTelInput.css";
import Swal from "sweetalert2";
import "../styles/swal.css";
import { BASE_API_URL } from "../utils/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import axios from "axios";
const Summary = React.memo((props) => {
  const { formData } = props;
  let navigate = useNavigate();
  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const { handleSubmit } = useForm();
  const handleSave = async () => {
    try {
      const response = await axios.post(`${BASE_API_URL}/register`, formData);
      Swal.fire({
        title: "!ممتاز",
        text: "لقد تمّت عملية تسجيلك بنجاح",
        icon: "success",
        customClass: {
          title: "success-title",
          container: "custom-container",
        },
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          props.resetUser();
          navigate("/");
        }
      });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "",
          text: error.response.data,
          customClass: {
            title: "error-title",
            container: "custom-container",
          },
        }).then(() => {
          navigate("/info");
        });
      }
    }
  };
  return (
    <Form className=" mx-auto my-16  " onSubmit={handleSubmit(handleSave)}>
      <motion.div
        className="col-md-8 offset-md-2"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <div className="flex justify-center ">
              <div className="inline-block justify-center rounded-[20px] border-solid border-2 border-[rgb(24,242,16)] p-[50px] md:p-[100px] ">
                <div className="top-0">
                  <div className="flex justify-center items-start">
                    <CheckCircleIcon
                      style={{
                        color: "rgb(24,242,16)",
                        width: "90px",
                        height: "90px",
                      }}
                    />
                  </div>
                  <div className="mt-4  w-[200px]  md:w-[400px]">
                    <h1
                      className="text-[20px] md:text-[25px]"
                      style={{
                        fontFamily: "Bold",
                        color: "black",
                      }}
                    >
                      {" "}
                      أنت أقرب بخطوة للحصول على فرصة عمل في الخارج
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </CacheProvider>

        <div className="flex justify-center">
          <div className="flex-col justify-center gap-5 p-4">
            <div className="flex justify-center  ">
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
              >
                تسجيل
              </Button>
            </div>
            <div className="flex justify-center">
              <h6 className="text-[15px] font-[Regular] pt-2 text-[#108cf2]">
                اضغط هنا لتسجيل الطلب
              </h6>
            </div>
          </div>
        </div>
      </motion.div>
    </Form>
  );
});
export default Summary;
