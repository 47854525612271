import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "rgba(16, 140, 242, 1)",
        padding: "1px",
        textAlign: "center",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <div style={{ margin: "0 0" }}></div>
      <p
        className="text-[11px] md:text-[15px] text-white"
        style={{ margin: 0, fontFamily: "Bold", color: "white" }}
      >
        © Forsa 2024
      </p>
    </footer>
  );
};

export default Footer;
