import { z } from "zod";

const englishPattern = /^[a-zA-Z\s]+/;
const charsPattern = /^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

export const InfoSchema = z.object({
  arabic_first_name: z
    .string({ invalid_type_error: "الرجاء تعبئة الاسم الأول" })
    .min(3, { message: "الاسم قصير جدًا" })
    .regex(/^[^a-zA-Z]+$/, {
      message: "الرجاء استخدام الأحرف العربية فقط",
    })
    .regex(/^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, {
      message: "الرجاء استخدام الأحرف فقط",
    }),
  arabic_father_name: z
    .string({ invalid_type_error: "الرجاء تعبئة اسم الأب" })
    .min(3, { message: "الاسم قصير جدًا" })
    .regex(/^[^a-zA-Z]+$/, {
      message: "الرجاء استخدام الأحرف العربية فقط",
    })
    .regex(/^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, {
      message: "الرجاء استخدام الأحرف فقط",
    }),
  arabic_last_name: z
    .string({ invalid_type_error: "الرجاء تعبئة اسم الشهرة" })
    .min(3, { message: "الاسم قصير جدًا" })
    .regex(/^[^a-zA-Z]+$/, {
      message: "الرجاء استخدام الأحرف العربية فقط",
    })
    .regex(/^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, {
      message: "الرجاء استخدام الأحرف فقط",
    }),
  arabic_mother_name: z
    .string({ invalid_type_error: "الرجاء تعبئة اسم اﻷم" })
    .min(3, { message: "الاسم قصير جدًا" })
    .regex(/^[^a-zA-Z]+$/, {
      message: "الرجاء استخدام الأحرف العربية فقط",
    })
    .regex(/^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, {
      message: "الرجاء استخدام الأحرف فقط",
    }),
  first_name: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null ||
        value.trim() === "" ||
        (englishPattern.test(value) && charsPattern.test(value)),
      {
        message: "الرجاء استخدام الأحرف الانجليزية فقط",
      }
    ),
  last_name: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null ||
        value.trim() === "" ||
        (englishPattern.test(value) && charsPattern.test(value)),
      {
        message: "الرجاء استخدام الأحرف الانجليزية فقط",
      }
    ),
  father_name: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null ||
        value.trim() === "" ||
        (englishPattern.test(value) && charsPattern.test(value)),
      {
        message: "الرجاء استخدام الأحرف الانجليزية فقط",
      }
    ),
  mother_name: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null ||
        value.trim() === "" ||
        (englishPattern.test(value) && charsPattern.test(value)),
      {
        message: "الرجاء استخدام الأحرف الانجليزية فقط",
      }
    ),
  place_of_birth: z.string(),

  //birth_date: z.string(),
  origin: z.string(),
  nationality: z.string(),
});
