import React from "react";
import loading from "../images/Loading-pana.png";
export default function LoadingPrime() {
  return (
    <div className="flex justify-center items-center content-center ">
      <div className="relative bg-[url('../images/Loading-rafiki.png')] md:bg-[url('../images/Loading-pana.png')]  w-[600px] h-[600px] md:w-[900px] md:h-[900px] bg-cover bg-center bg-no-repeat mb-0   flex justify-center items-center">
        {" "}
      </div>
    </div>
  );
}
