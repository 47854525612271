import { z } from "zod";

const noCharsPattern = /^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

export const StatusSchema = z.object({
  isMarried: z.string().optional().nullable(),

  partner_name: z
    .string()
    .optional()
    .nullable()
    .refine(
      (value) => value === null || value === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الاحرف فقط",
      }
    ),

  kids: z.string().optional().nullable(),
  number_of_children: z
    .string()
    .nullable()
    .refine(
      (value) => value === null || value === "" || parseInt(value, 10) >= 0,
      {
        message: "عدد الأولاد يجب أن يكون أكبر من أو يساوي 0",
      }
    )
    .refine(
      (value) => value === null || value === "" || parseInt(value, 10) <= 20,
      {
        message: "عدد الأولاد يجب أن يكون أقل من أو يساوي 20",
      }
    ),

  bring_kids_with: z.string().optional().nullable(),
});
