import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import Summary from "../components/Summary";
import Loading from "../components/LoadingPrime";
import Status from "../components/Status";
import Footer from "../components/Footer";
import Travel from "../components/Travel";
import SocialMedia from "../components/SocialMedia";
import Info from "../components/Info";
import Residence from "../components/Residence";
import Resume from "../components/Resume";
import Progress from "../components/ProgressBar";
import { v4 as uuidv4 } from "uuid";
const Header = lazy(() => import("../components/Header"));
const Documents = lazy(() => import("../components/Documents"));
const NotFound = lazy(() => import("../components/NotFound"));
const RouteGuard = lazy(() => import("../components/RouteGuard"));
const ScrollToTop = lazy(() => import("../components/ScrollToTop"));
const LandingPage = lazy(() => import("../components/LandingPage"));
const AppRouter = () => {
  const [userId, setUserId] = useState(null);
  const [formData, setFormData] = useState(new FormData());
  const updateUser = (data) => {
    for (const key in data) {
      formData.set(key, data[key]);
    }
    setFormData(formData);
  };
  const resetUser = () => {
    setFormData(new FormData());
  };
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");

    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      const newUserId = uuidv4();
      localStorage.setItem("userId", newUserId);
      setUserId(newUserId);
    }
  }, []);

  useEffect(() => {
    const formDataString = localStorage.getItem("formData");
    if (!formDataString) {
      const initialFormData = {
        arabic_first_name: "",
        arabic_father_name: "",
        arabic_last_name: "",
        arabic_mother_name: "",
        first_name: "",
        last_name: "",
        father_name: "",
        mother_name: "",
        place_of_birth: "",
        birth_date: "",
        origin: "",
        nationality: "",
        first_phone_number: "",
        second_phone_number: "",
        email: "",
        facebook: "",
        instagram: "",
        twitter: "",
        residence: "",
        isMarried: "",
        kids: "",
        partner: "",
        bring_kids_with: "",
        number_of_children: "",
        education: "",
        school: "",
        speciality: "",
        study_year: "",
        skills: "",
        current_job: "",
        work_place: "",
        work_year: "",
        dream_job: "",
        dream_country: "",
      };

      localStorage.setItem("formData", JSON.stringify(initialFormData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("infoCompleted", "false");
    localStorage.setItem("socialMediaCompleted", "false");
    localStorage.setItem("sendData", "false");
  }, []);
  const DelayedInfo = ({ children, delay }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
      }, delay);

      return () => clearTimeout(timer);
    }, [delay]);
    return children;
  };

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        {" "}
        <ScrollToTop>
          <div>
            <Header />
            <Progress />
            <Footer />
            <Routes>
              <Route
                path="/"
                element={
                  <LandingPage formData={formData} updateUser={updateUser} />
                }
              />
              <Route
                path="/info"
                element={<Info formData={formData} updateUser={updateUser} />}
              />
              <Route path="/loading" element={<Loading />} />
              <Route
                path="/socialmedia"
                element={
                  <RouteGuard state="infoCompleted" redirectPath="/info">
                    <SocialMedia formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />
              <Route
                path="/residence"
                element={
                  <RouteGuard
                    state="socialMediaCompleted"
                    redirectPath="/socialmedia"
                  >
                    <Residence formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />

              <Route
                path="/status"
                element={
                  <RouteGuard
                    state="socialMediaCompleted"
                    redirectPath="/socialmedia"
                  >
                    <Status formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />

              <Route
                path="/resume"
                element={
                  <RouteGuard
                    state="socialMediaCompleted"
                    redirectPath="/socialmedia"
                  >
                    <Resume formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />
              <Route
                path="/travel"
                element={
                  <RouteGuard
                    state="socialMediaCompleted"
                    redirectPath="/socialmedia"
                  >
                    <Travel formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />
              <Route
                path="/doc"
                element={
                  <RouteGuard
                    state="socialMediaCompleted"
                    redirectPath="/socialmedia"
                  >
                    <Documents formData={formData} updateUser={updateUser} />
                  </RouteGuard>
                }
              />

              <Route
                path="/sum"
                element={<Summary formData={formData} resetUser={resetUser} />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>{" "}
        </ScrollToTop>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;
