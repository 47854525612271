import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResumeImage from "../images/Resume folder-rafiki(1).png";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResumeSchema } from "../schemas/resumeSchema";
import "../styles/MuiTelInput.css";
import dayjs from "dayjs";

const Resume = (props) => {
  let navigate = useNavigate();

  let newWorkDate =
    JSON.parse(localStorage.getItem("formData"))?.work_year || null;
  let newStudyDate =
    JSON.parse(localStorage.getItem("formData"))?.study_year || null;

  const [workDate, setWorkDate] = useState(dayjs(newWorkDate));
  const [studyDate, setStudyDate] = useState(dayjs(newStudyDate));
  const [education1, setEducation] = useState(null);
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData) {
      if (formData.education) {
        setEducation(formData.education);
      }
    }
  }, []);

  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    return savedData ? JSON.parse(savedData) : {};
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));

    if (formData) {
      props.updateUser(formData);
    }
  }, []);
  const [formData, setFormData] = useState(loadFormDataFromLocalStorage());
  const saveFormDataToLocalStorage = (data) => {
    const existingData = loadFormDataFromLocalStorage();
    const updatedData = { ...existingData, ...data };
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };

  let formDataString = localStorage.getItem("formData");
  let defaultValues = JSON.parse(formDataString);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name).then((isValid) => {
      if (isValid) {
        saveFormDataToLocalStorage({ [name]: value });

        formDataString = localStorage.getItem("formData");
        defaultValues = JSON.parse(formDataString);
        props.updateUser({ [name]: value });
      }
    });
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ResumeSchema),

    defaultValues: formData,
  });

  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const onSubmit = (data) => {
    props.updateUser(data);

    saveFormDataToLocalStorage(data);
    //props.handleFormCompletion();

    navigate("/travel");
  };

  let defaultEducation = null;
  if (formDataString) {
    const formData = JSON.parse(formDataString);

    const Education = formData.education || " ";
    defaultEducation = education.find((educationStatus) => {
      return educationStatus.label === Education;
    });
  }

  return (
    <div className="flex">
      {" "}
      <div id="form-start"></div>
      <Form
        className=" w-full md:w-1/2  my-[150px]  "
        onSubmit={handleSubmit(onSubmit)}
      >
        <motion.div
          className="col-md-8 offset-md-2"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}
        >
          {window.innerWidth < 768 && (
            <div className="flex justify-center">
              <img
                className="w-[200px] h-[200px]"
                src={ResumeImage}
                alt="description"
              />
            </div>
          )}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[30px] md:text-[40px]">
                    {" "}
                    السيرة العملية{" "}
                  </h1>
                </Form.Label>{" "}
              </div>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[20px] md:text-[25px]">
                    {" "}
                    آخر صف دراسي{" "}
                  </h1>
                </Form.Label>{" "}
              </div>
              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap"
                dir="rtl"
              >
                <Form.Group dir="rtl">
                  <Controller
                    name="education"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => {
                      return (
                        <Autocomplete
                          value={defaultEducation}
                          sx={{
                            width: 250,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { border: "1px solid #108cf2" },
                            },
                          }}
                          id="education"
                          options={education}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => {
                            setEducation(data ? data.label : "");
                            onChange(data ? data.label : "");
                          }}
                          renderOption={(props, option) => (
                            <div className="flex justify-center">
                              <Box
                                className="flex justify-center"
                                style={{
                                  fontFamily: "Medium",
                                  color: "#225075",
                                }}
                                component="li"
                                {...props}
                              >
                                {option.label}
                              </Box>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              inputRef={ref}
                              label="المستوى العلمي"
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  fontFamily: "Medium",
                                  color: "#225075",
                                },
                                onBlur: (e) => handleInputChange(e),
                                autoComplete: "new-password",
                              }}
                              InputLabelProps={{
                                style: {
                                  fontFamily: "Medium",
                                  color: "#108cf2",
                                },
                              }}
                              error={errors.place_of_birth !== undefined}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  {errors.place_of_birth && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.place_of_birth.message}
                    </p>
                  )}
                </Form.Group>
                {education1 === "غير" && (
                  <Form.Group controlId="school" dir="rtl">
                    <TextField
                      sx={{
                        width: 250,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { border: "1px solid #108cf2" },
                        },
                      }}
                      id="outlined-required"
                      label="غير ذلك"
                      {...register("school")}
                      inputProps={{
                        style: { fontFamily: "Medium", color: "#225075" },
                        onBlur: (e) => handleInputChange(e),
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Medium", color: "#108cf2" },
                      }}
                      error={errors.school !== undefined}
                    />
                    {errors.school && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.school.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                {(education1 === "جامعي" ||
                  education1 === "مهني" ||
                  education1 === "ابتدائي") && (
                  <Form.Group controlId="school" dir="rtl">
                    <TextField
                      sx={{
                        width: 250,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { border: "1px solid #108cf2" },
                        },
                      }}
                      id="outlined-required"
                      label="اسم المدرسة\الجامعة\المعهد"
                      {...register("school")}
                      inputProps={{
                        style: { fontFamily: "Medium", color: "#225075" },
                        onBlur: (e) => handleInputChange(e),
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Medium", color: "#108cf2" },
                      }}
                      error={errors.school !== undefined}
                    />
                    {errors.school && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.school.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                {(education1 === "جامعي" ||
                  education1 === "مهني" ||
                  education1 === "ابتدائي") && (
                  <Form.Group controlId="speciality" dir="rtl">
                    <TextField
                      sx={{
                        width: 250,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { border: "1px solid #108cf2" },
                        },
                      }}
                      id="outlined-required"
                      label="الإختصاص"
                      {...register("speciality")}
                      inputProps={{
                        style: { fontFamily: "Medium", color: "#225075" },
                        onBlur: (e) => handleInputChange(e),
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Medium", color: "#108cf2" },
                      }}
                      error={errors.speciality !== undefined}
                    />
                    {errors.speciality && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.speciality.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                {(education1 === "جامعي" ||
                  education1 === "مهني" ||
                  education1 === "ابتدائي") && (
                  <Form.Group
                    controlId="study_year"
                    style={{ fontFamily: "Medium" }}
                  >
                    <Controller
                      name="study_year"
                      control={control}
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={["year"]}
                              sx={{
                                width: 250,
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": {
                                    border: "1px solid #108cf2",
                                  },
                                },
                              }}
                              slotProps={{
                                textField: {
                                  InputLabelProps: {
                                    style: {
                                      fontFamily: "Medium",
                                      color: "#108cf2",
                                    },
                                  },
                                  inputProps: {
                                    style: {
                                      fontFamily: "Medium",
                                      color: "#225075",
                                    },
                                  },
                                },
                              }}
                              label="السنة الدراسية"
                              {...field}
                              defaultValue={
                                studyDate && studyDate.isValid()
                                  ? studyDate
                                  : null
                              }
                              value={
                                studyDate && studyDate.isValid()
                                  ? studyDate
                                  : null
                              }
                              onChange={(newValue) => {
                                setStudyDate(newValue);
                                saveFormDataToLocalStorage({
                                  study_year: dayjs(newValue).format("YYYY"),
                                });
                                props.updateUser({
                                  study_year: dayjs(newValue).format("YYYY"),
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </Form.Group>
                )}
              </div>

              <div style={{ fontFamily: "Bold" }}>
                <Form.Group
                  controlId="skills"
                  dir="rtl"
                  style={{ fontFamily: "Bold" }}
                >
                  <TextField
                    dir="rtl"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    fullWidth
                    id="outlined-required"
                    label="مهارات مكتسبة (أمثلة:تصليح سيارات،حلاقة،كهرباء...) "
                    multiline
                    rows={5}
                    defaultValue=""
                    {...register("skills")}
                    InputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    style={{ fontFamily: "Bold", fontWeight: "bold" }}
                    error={errors.skills !== undefined}
                  />
                  {errors.skills && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.skills.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[20px] md:text-[25px]">
                    {" "}
                    ماذا تعمل حاليا؟{" "}
                  </h1>
                </Form.Label>{" "}
              </div>

              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap"
                dir="rtl"
              >
                <Form.Group controlId="current_job" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label=" العمل الحالي "
                    {...register("current_job")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.current_job !== undefined}
                  />
                  {errors.current_job && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.current_job.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="work_place" dir="rtl">
                  <TextField
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #108cf2" },
                      },
                    }}
                    id="outlined-required"
                    label="مكان العمل "
                    {...register("work_place")}
                    inputProps={{
                      style: { fontFamily: "Medium", color: "#225075" },
                      onBlur: (e) => handleInputChange(e),
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Medium", color: "#108cf2" },
                    }}
                    error={errors.work_place !== undefined}
                  />
                  {errors.work_place && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.work_place.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="work_year"
                  style={{ fontFamily: "Medium" }}
                >
                  <Controller
                    name="work_year"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year"]}
                            sx={{
                              width: 250,
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  border: "1px solid #108cf2",
                                },
                              },
                            }}
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#108cf2",
                                  },
                                },
                                inputProps: {
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#225075",
                                  },
                                },
                              },
                            }}
                            label="السنة "
                            {...field}
                            defaultValue={
                              workDate && workDate.isValid() ? workDate : null
                            }
                            value={
                              workDate && workDate.isValid() ? workDate : null
                            }
                            onChange={(newValue) => {
                              setWorkDate(newValue);
                              saveFormDataToLocalStorage({
                                work_year: dayjs(newValue).format("YYYY"),
                              });
                              props.updateUser({
                                work_year: dayjs(newValue).format("YYYY"),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </Form.Group>
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center pt-4">
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
            >
              حفظ
            </Button>
          </div>
        </motion.div>
      </Form>
      {window.innerWidth >= 768 && (
        <div className="flex justify-center w-1/2 items-center my-16">
          <div>
            <img
              className=" w-[200px] h-[200px] md:w-[800px] md:h-[800px]"
              src={ResumeImage}
              alt="description"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Resume;

const education = [
  { label: "جامعي" },
  { label: "مهني" },
  { label: "ابتدائي" },
  { label: "غير" },
];
