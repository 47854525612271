import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import StatusImage from "../images/asian family-rafiki.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import "../styles/index.css";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf";
import "../IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf";
import { zodResolver } from "@hookform/resolvers/zod";
import { StatusSchema } from "../schemas/statusSchema";
import "../styles/MuiTelInput.css";

const Status = (props) => {
  let navigate = useNavigate();
  const [isMarried, setIsMarried] = useState(null);
  const [kids, setKids] = useState(null);
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData) {
      if (formData.isMarried) {
        setIsMarried(formData.isMarried);
      }
      if (formData.kids) {
        setKids(formData.kids);
      }
    }
  }, []);

  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    return savedData ? JSON.parse(savedData) : {};
  };
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    if (formData) {
      props.updateUser(formData);
    }
  }, []);
  const [formData, setFormData] = useState(loadFormDataFromLocalStorage());
  const saveFormDataToLocalStorage = (data) => {
    const existingData = loadFormDataFromLocalStorage();
    const updatedData = { ...existingData, ...data };
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };
  let formDataString = localStorage.getItem("formData");
  let defaultValues = JSON.parse(formDataString);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name).then((isValid) => {
      if (isValid) {
        saveFormDataToLocalStorage({ [name]: value });

        props.updateUser({ [name]: value });
      }
    });
  };

  let defaultIsMarried = null;
  let defaultKids = null;
  let defaultBringKidsWith = null;
  if (formDataString) {
    const formData = JSON.parse(formDataString);

    const Married = formData.isMarried || " ";
    defaultIsMarried = MaritalStatus.find((married) => {
      return married.label === Married;
    });

    const numberOfKids = formData.kids || " ";
    defaultKids = kidsOptions.find((kidsNumber) => {
      return kidsNumber.label === numberOfKids;
    });

    const bring_kids = formData.bring_kids_with || " ";
    defaultBringKidsWith = bring_kids_with.find((brindKids) => {
      return brindKids.label === bring_kids;
    });
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(StatusSchema),
    defaultValues: formData,
  });

  const theme = createTheme({ direction: "rtl" });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const onSubmit = (data) => {
    props.updateUser(data);

    saveFormDataToLocalStorage(data);

    navigate("/resume");
  };

  return (
    <div className="flex">
      {" "}
      <div id="form-start"></div>
      <Form
        className="  w-full md:w-1/2  my-[150px]  "
        onSubmit={handleSubmit(onSubmit)}
      >
        <motion.div
          className="col-md-8 offset-md-2"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}
        >
          {window.innerWidth < 768 && (
            <div className="flex justify-center">
              <img
                className="w-[200px] h-[200px]"
                src={StatusImage}
                alt="description"
              />
            </div>
          )}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="flex justify-center pt-10 text-[25px] md:text-[40px]">
                <Form.Label>
                  <h1 className="font-[Bold] text-[30px] md:text-[40px]">
                    {" "}
                    الوضع الاجتماعي{" "}
                  </h1>
                </Form.Label>{" "}
              </div>

              <div
                className="flex justify-center flex-wrap gap-4 pt-3 2xl:justify-center 2xl:flex-nowrap "
                dir="rtl"
              >
                <Form.Group dir="rtl">
                  <Controller
                    name="isMarried"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => {
                      return (
                        <Autocomplete
                          value={defaultIsMarried}
                          sx={{
                            width: 250,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { border: "1px solid #108cf2" },
                            },
                          }}
                          id="isMarried"
                          options={MaritalStatus}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => {
                            setIsMarried(data ? data.label : "");
                            onChange(data ? data.label : "");
                          }}
                          renderOption={(props, option) => (
                            <div className="flex justify-center">
                              <Box
                                className="flex justify-center"
                                style={{
                                  fontFamily: "Medium",
                                  color: "#225075",
                                }}
                                component="li"
                                {...props}
                              >
                                {option.label}
                              </Box>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              inputRef={ref}
                              label="الحالة الاجتماعية*"
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  fontFamily: "Medium",
                                  color: "#225075",
                                },
                                onBlur: (e) => handleInputChange(e),
                                autoComplete: "new-password",
                              }}
                              InputLabelProps={{
                                style: {
                                  fontFamily: "Medium",
                                  color: "#108cf2",
                                },
                              }}
                              error={errors.isMarried !== undefined}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  {errors.isMarried && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.isMarried.message}
                    </p>
                  )}
                </Form.Group>
                {(isMarried === "متزوج/متزوجة" ||
                  isMarried === "مطلق/مطلقة" ||
                  isMarried === "أرمل/أرملة") && (
                  <Form.Group controlId="partner_name" dir="rtl">
                    <TextField
                      className="customTextField"
                      dir="rtl"
                      sx={{
                        width: 250,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { border: "1px solid #108cf2" },
                        },
                      }}
                      id="outlined-required"
                      label="اسم الزوج/ة"
                      {...register("partner_name")}
                      inputProps={{
                        style: { fontFamily: "Medium", color: "#225075" },
                        onBlur: (e) => handleInputChange(e),
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Medium", color: "#108cf2" },
                      }}
                      error={errors.partner_name !== undefined}
                    />
                    {errors.partner_name && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.partner_name.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                <Form.Group dir="rtl">
                  <Controller
                    name="kids"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => {
                      return (
                        <Autocomplete
                          value={defaultKids}
                          sx={{
                            width: 250,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { border: "1px solid #108cf2" },
                            },
                          }}
                          id="kids"
                          options={kidsOptions}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => {
                            setKids(data ? data.label : "");
                            onChange(data ? data.label : "");
                          }}
                          renderOption={(props, option) => (
                            <div className="flex justify-center">
                              <Box
                                className="flex justify-center"
                                style={{
                                  fontFamily: "Medium",
                                  color: "#225075",
                                }}
                                component="li"
                                {...props}
                              >
                                {option.label}
                              </Box>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              inputRef={ref}
                              label="هل لديك أولاد؟"
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  fontFamily: "Medium",
                                  color: "#225075",
                                },
                                onBlur: (e) => handleInputChange(e),
                                autoComplete: "new-password",
                              }}
                              InputLabelProps={{
                                style: {
                                  fontFamily: "Medium",
                                  color: "#108cf2",
                                },
                              }}
                              error={errors.kids !== undefined}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  {errors.kids && (
                    <p
                      className="errorMsg"
                      style={{ fontFamily: "Regular", textAlign: "right" }}
                    >
                      {errors.kids.message}
                    </p>
                  )}
                </Form.Group>
                {kids === "نعم" && (
                  <Form.Group controlId="number_of_children">
                    <TextField
                      sx={{
                        width: 250,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { border: "1px solid #108cf2" },
                        },
                      }}
                      type="number"
                      id="outlined-required"
                      InputLabelProps={{
                        style: { fontFamily: "Medium", color: "#108cf2" },
                      }}
                      inputProps={{
                        style: { fontFamily: "Medium", color: "#225075" },
                        onBlur: (e) => handleInputChange(e),
                      }}
                      label="عدد الأولاد*"
                      {...register("number_of_children")}
                      error={
                        kids === "نعم" &&
                        errors.number_of_children !== undefined
                      }
                    />

                    {kids === "نعم" && errors.number_of_children && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.number_of_children.message}
                      </p>
                    )}
                  </Form.Group>
                )}

                {kids === "نعم" && (
                  <Form.Group dir="rtl">
                    <Controller
                      name="bring_kids_with"
                      control={control}
                      render={({ field: { ref, onChange, ...field } }) => {
                        return (
                          <Autocomplete
                            value={defaultBringKidsWith}
                            sx={{
                              width: 250,
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { border: "1px solid #108cf2" },
                              },
                            }}
                            id="kids"
                            options={bring_kids_with}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            onChange={(_, data) => {
                              onChange(data ? data.label : "");
                            }}
                            renderOption={(props, option) => (
                              <div className="flex justify-center">
                                <Box
                                  className="flex justify-center"
                                  style={{
                                    fontFamily: "Medium",
                                    color: "#225075",
                                  }}
                                  component="li"
                                  {...props}
                                >
                                  {option.label}
                                </Box>
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...field}
                                inputRef={ref}
                                label="هل ترغب في اصطحاب أولادك؟"
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#225075",
                                  },
                                  onBlur: (e) => handleInputChange(e),
                                  autoComplete: "new-password",
                                }}
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Medium",
                                    color: "#108cf2",
                                  },
                                }}
                                error={errors.bring_kids_with !== undefined}
                              />
                            )}
                          />
                        );
                      }}
                    />
                    {errors.bring_kids_with && (
                      <p
                        className="errorMsg"
                        style={{ fontFamily: "Regular", textAlign: "right" }}
                      >
                        {errors.bring_kids_with.message}
                      </p>
                    )}
                  </Form.Group>
                )}
              </div>
            </ThemeProvider>
          </CacheProvider>

          <div className="flex justify-center pt-4">
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#108cf2", fontFamily: "Bold" }}
            >
              حفظ
            </Button>
          </div>
        </motion.div>
      </Form>
      {window.innerWidth >= 768 && (
        <div className="flex justify-center w-1/2 items-center my-16">
          <div>
            <img
              className=" w-[200px] h-[200px] md:w-[500px] md:h-[500px]"
              src={StatusImage}
              alt="description"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Status;

const MaritalStatus = [
  { label: "متزوج/متزوجة" },
  { label: "أعزب/عزباء" },
  { label: "مطلق/مطلقة" },
  { label: "أرمل/أرملة" },
];
const kidsOptions = [{ label: "نعم" }, { label: "كلا" }];
const bring_kids_with = [{ label: "نعم" }, { label: "كلا" }];
