import { z } from "zod";

const noCharsPattern = /^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
export const ResidenceSchema = z.object({
  residence: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || noCharsPattern.test(value),
      {
        message: "الرجاء استخدام الأحرف فقط",
      }
    ),
});
