import { z } from "zod";
import { matchIsValidTel } from "mui-tel-input";

const facebookPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
const instagramPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com\/)(?:[a-zA-Z0-9._]+)(?:\/)?/i;
const twitterPattern =
  /^(?:(?:http|https):\/\/)?(?:www\.)?(?:twitter\.com\/)(?:[a-zA-Z0-9_]+)(?:\/)?/i;
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phonePattern = /^\d+$/;

export const SocialMediaSchema = z.object({
  first_phone_number: z
    .string({ invalid_type_error: "الرجاء تعبئة رقم الهاتف" })
    .refine((value) => matchIsValidTel(value), {
      message: "رقم الهاتف غير صحيح",
    }),
  second_phone_number: z
    .string()
    .nullable()
    .refine(
      (value) => {
        if (value === null || value.trim() === "") {
          return true; // Return true if the value is null or empty
        }
        return matchIsValidTel(value); // Validate the value using matchIsValidTel function
      },
      { message: "صيغة الرقم غير صحيحة" }
    ),

  email: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || emailPattern.test(value),
      {
        message: "صيغة البريد الإلكتروني غير صحيحة",
      }
    ),

  facebook: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || facebookPattern.test(value),
      { message: "صيغة الحساب غير صحيحة" }
    ),

  instagram: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || instagramPattern.test(value),
      { message: "صيغة الحساب غير صحيحة" }
    ),

  twitter: z
    .string()
    .nullable()
    .refine(
      (value) =>
        value === null || value.trim() === "" || twitterPattern.test(value),
      { message: "صيغة الحساب غير صحيحة" }
    ),
});
